import React from 'react';
import { ArrowRight, Mail } from "react-feather";

const MainView = ({ showBetaForm, showMailingListForm }) => {
    const navigateToURL = () => {
        window.location.href = 'https://app.tourneycompanion.com';
    };

    return <div className="main-content">
        <button className="floating-login-button" onClick={navigateToURL}>Tournament Organizer Login</button>
        <div className="section">
            <h1>The next <span style={{color: "#4ca4e5"}}>evolution of tournaments</span> has arrived</h1>
        </div>
        <div className="section">
            <h2>The <span style={{color: "#4ca4e5"}}>Tourney Companion</span> <span
                className="summer-text">Summer 2024</span> closed beta is officially underway! Want to blast your Smash Ultimate tournaments into the
                future? <span
                    className="enroll-text">Enroll below!</span></h2>
        </div>
        <div className="section">
            <div className="button-container">
                <button id="enrollButton" className="enroll-button" onClick={showBetaForm}>
                    Enroll in Beta <ArrowRight className="icon"/>
                </button>
                <button id="joinButton" className="join-button" onClick={showMailingListForm}>
                    Join Mailing List <Mail className="icon"/>
                </button>
            </div>
        </div>
    </div>;
};

export default MainView;