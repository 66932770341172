import React, { useRef } from 'react';
import {ArrowLeft} from "react-feather";

const JoinMailingListView = ({ goBack }) => {
    const formRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        goBack();
        formRef.current.submit();
    };

    return (
        <div className="enrollment-form">
            <h1><span style={{color: "#4ca4e5"}}>Join the Mailing List</span></h1>
            <form ref={formRef} action="https://docs.google.com/forms/d/e/1FAIpQLSfvzCAkjnmzvhKpey4KLV2UOw7Ox74qP3LpFBqfJl5ZsacpiA/formResponse" method="POST" target="_blank" onSubmit={handleSubmit}>
                <div className='form-fields'>
                    <div className="form-field-container">
                        <label htmlFor="entry.1988335545"><span className="required">* </span>Your Name:</label>
                        <input className="form-field" type="text" id="entry.1988335545" name="entry.1988335545" required/>
                    </div>
                    <div className="form-field-container">
                        <label htmlFor="entry.80659634"><span className="required">* </span>Your Email:</label>
                        <input className="form-field" type="email" id="entry.80659634" name="entry.80659634" required/>
                    </div>
                    <div className="form-field-container">
                        <label htmlFor="entry.1992810881"><span className="required">* </span>What's your role in tournaments?</label>
                        <select className="form-field" id="entry.1992810881" name="entry.1992810881" required>
                            <option value="">Select...</option>
                            <option value="Organizer">Organizer</option>
                            <option value="Competitor">Competitor</option>
                            <option value="Both">Both</option>
                        </select>
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="goBack-button" onClick={goBack}><ArrowLeft/></button>
                    <button className="join-mailing-list-button" type="submit">Join</button>
                </div>
            </form>
        </div>
    );
};

export default JoinMailingListView;