import React, { useRef } from 'react';
import {ArrowLeft} from "react-feather";

const BetaEnrollmentView = ({ goBack }) => {
    const formRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        goBack();
        formRef.current.submit();
    };

    return (
        <div className="enrollment-form">
            <h1><span style={{color: "#4ca4e5"}}>Enroll in the Tourney Companion Beta</span> (Tournament Organizers Only)</h1>
            <form ref={formRef} action="https://docs.google.com/forms/d/e/1FAIpQLSfakX--z3gb1x4b01NcR2VLgBE_6XAkIBxr_2st5mKTDT9gmw/formResponse" method="POST" target="_blank" onSubmit={handleSubmit}>
                <div className='form-fields'>
                    <div className="form-field-container">
                        <label htmlFor="entry.6713651"><span className="required">* </span>Your Name:</label>
                        <input className="form-field" type="text" id="entry.6713651" name="entry.6713651" required/>
                    </div>
                    <div className="form-field-container">
                        <label htmlFor="entry.1197517435"><span className="required">* </span>Email Address:</label>
                        <input className="form-field" type="email" id="entry.1197517435" name="entry.1197517435" required/>
                    </div>
                    <div className="form-field-container">
                        <label htmlFor="entry.897216798">Discord Username:</label>
                        <input className="form-field" type="text" id="entry.897216798" name="entry.897216798"/>
                    </div>
                    <div className="form-field-container">
                        <label htmlFor="entry.775126075"><span className="required">* </span>Your Organization (or Tournament Series):</label>
                        <input className="form-field" type="text" id="entry.775126075" name="entry.775126075" required/>
                    </div>
                </div>
                <div className="buttons-container">
                    <button className="goBack-button" onClick={goBack}><ArrowLeft/></button>
                    <button className="submit-enrollment-button" type="submit">Enroll</button>
                </div>
            </form>
        </div>
    );
};

export default BetaEnrollmentView;