import React, {useCallback, useState} from 'react';
import './App.css';
import phoneScreens from './assets/phone_screens.png';
import logo from './assets/tc_logo_no_bg.png';
import MainView from "./components/MainView";
import BetaEnrollmentView from "./components/BetaEnrollmentView";
import JoinMailingListView from "./components/JoinMailingListView";

function App() {

    const [showBetaForm, setShowBetaForm] = useState(false);
    const [showMailingListForm, setShowMailingListForm] = useState(false);

    const goBack = useCallback(() => {
        setShowBetaForm(false);
        setShowMailingListForm(false);
    }, []);

    const renderContents = () => {
        if (showBetaForm) {
            return <BetaEnrollmentView goBack={goBack}/>;
        } else if (showMailingListForm) {
            return <JoinMailingListView goBack={goBack}/>;
        } else {
            return <MainView
                showBetaForm={() => setShowBetaForm(true)}
                showMailingListForm={() => setShowMailingListForm(true)}
            />
        }
    };

    return (
        <div className="container">
            {renderContents()}
            <div className="splash-image">
                <img className="phone-screens" src={phoneScreens} alt="Tournament Companion"/>
            </div>
            <img className="logo" src={logo} alt="Tourney Companion Logo"/>
        </div>
    );
}

export default App;